<template>
  <v-card
    :img="require(`@/assets/elements/background.webp`)"
    class="hero"
    color="black"
    height="100%"
    flat
    tile
  >
    <v-container class="pa-0" style="max-width: 1920px">
      <v-row class="ma-0">
        <v-col class="d-flex flex-column align-center justify-center pa-0" cols="12">
          <video
            ref="videoPlayer"
            width="100%"
            height="830"
            autoplay
            controls
            loop
            muted
            playsinline
          >
            <source :src="currentVideo" type="video/mp4" />
          </video>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "VideoHome",
  data() {
    return {
      currentVideo: this.getVideoSrc(),
    };
  },
  watch: {
    "$i18n.locale"() {
      this.currentVideo = this.getVideoSrc();
      this.reloadVideo();
    },
  },
  methods: {
    getVideoSrc() {
      return this.$i18n.locale === "es"
        ? require("@/assets/videos/on2024.mp4")
        : require("@/assets/videos/on2024.mp4");
    },
    reloadVideo() {
      this.$nextTick(() => {
        const video = this.$refs.videoPlayer;
        if (video) {
          video.load(); // Recarga el video cuando cambia el idioma
        }
      });
    },
  },
};
</script>
