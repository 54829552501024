<template>
  <div style="position: relative">
    <v-container style="padding-bottom: 200px; position: relative; z-index: 2">
      <v-row>
        <v-col class="d-flex align-center flex-column mb-12 text-center" cols="12">
          <v-img
            alt="On.Command™"
            class="mb-12"
            contain
            height="292"
            max-width="100%"
            src="@/assets/logos/on-command-light.webp"
            transition="fade-transition"
            width="400"
          />

          <div class="mt-16 mb-9 text-h3 font-weight-bold">
            {{ $t("interface_title") }}
          </div>

          <v-img
            alt="On.Command™ Energy Activity"
            class="d-none d-md-block mb-6"
            contain
            height="188"
            src="@/assets/elements/flow.webp"
            transition="fade-transition"
            width="100%"
          />

          <v-img
            alt="On.Command™ Energy Activity"
            class="d-md-none mt-6 mb-16"
            contain
            height="330"
            max-width="300"
            src="@/assets/elements/flow-mobile.webp"
            transition="fade-transition"
            width="100%"
          />

          <div class="text-h3 font-weight-bold">
            {{ $t("interface_power") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    interface_title: "all-new interface",
    interface_power: "easy. powerful. flexible",
    // English
  },
  es: {
    interface_title: "interfaz completamente nueva",
    interface_power: "fácil. potente. flexible",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Interface",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
