<template>
  <div>
    <v-row class="ma-0">
      <v-col
        class="d-flex flex-column align-center justify-center pa-0"
        cols="12"
        style="background-color: #000"
      >
        <video
          ref="videoPlayer"
          width="100%"
          height="830"
          autoplay
          controls
          loop
          muted
          playsinline
        >
          <source :src="currentVideo" type="video/mp4" />
        </video>
      </v-col>
    </v-row>

    <EndToEndQuote />

    <v-container style="padding-top: 100px; padding-bottom: 156px">
      <LatestProjects />
    </v-container>

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import EndToEndQuote from "@/components/solutions/end-to-end/EndToEndQuote.vue";
import LatestProjects from "@/components/LatestProjects.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "EndToEnd",
  components: { EndToEndQuote, LatestProjects, ContactUs },
  data() {
    return {
      currentVideo: this.getVideoSrc(),
    };
  },
  watch: {
    "$i18n.locale"() {
      this.currentVideo = this.getVideoSrc();
      this.reloadVideo();
    },
  },
  methods: {
    getVideoSrc() {
      return this.$i18n.locale === "es"
        ? require("@/assets/videos/on-end-to-end.mp4")
        : require("@/assets/videos/on-end-to-end.mp4");
    },
    reloadVideo() {
      this.$nextTick(() => {
        const video = this.$refs.videoPlayer;
        if (video) {
          video.load(); // Recarga el video cuando cambia el idioma
        }
      });
    },
  },
};
</script>
