<template>
  <v-card class="py-16" color="primary" flat tile>
    <v-container>
      <v-row class="d-flex justify-center text-center">
        <v-col class="d-flex justify-center" cols="12">
          <v-img
            alt="On.Energy"
            contain
            src="@/assets/elements/latest.webp"
            height="auto"
            max-width="300"
            transition="fade-transition"
            width="100%"
          />
        </v-col>

        <v-col class="my-3" cols="12" md="8"> “{{ $t("end_quote") }}” </v-col>

        <v-col class="my-6 text-h5 font-weight-bold" cols="12"> alan cooper, CEO </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    end_quote:
      "our competitive advantage lies in our ability to pair extensive project execution experience and proprietary software with a demonstrated ability to originate, develop and finance battery energy storage systems across various power markets",
    // English
  },
  es: {
    end_quote:
      "nuestra ventaja competitiva radica en nuestra capacidad para combinar una amplia experiencia en ejecución de proyectos y software propio con una habilidad comprobada para originar, desarrollar y financiar sistemas de almacenamiento de energía con baterías en diversos mercados eléctricos.",
    // Español
  },
};

createI18n(messages);

export default {
  name: "EndToEndQuote",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
