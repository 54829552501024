<template>
  <div>
    <SectionHead
      title="On.Command™"
      :text="$t('soft_text')"
      image="hero/software2025.webp"
    />

    <v-card class="mt-0" color="black" dark flat tile style="padding-top: 160px">
      <Interface />
    </v-card>

    <v-card class="kpis" color="primary" flat tile>
      <SoftwareKpis />
    </v-card>

    <v-card class="mt-0" color="black" dark flat tile style="padding: 160px 0">
      <Features />
    </v-card>

    <SoftwareWelcome />

    <Applications />

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import { createI18n } from "@/i18n";
import SectionHead from "@/components/SectionHead.vue";
import SoftwareWelcome from "@/components/solutions/software/SoftwareWelcome.vue";
import Interface from "@/components/solutions/software/Interface.vue";
import Features from "@/components/solutions/software/Features.vue";
import Applications from "@/components/solutions/software/Applications.vue";
import SoftwareKpis from "@/components/solutions/software/SoftwareKpis.vue";
import ContactUs from "@/components/ContactUs.vue";

const messages = {
  en: {
    soft_text: "On.Energy's energy management system",
    // English
  },
  es: {
    soft_text: "sistema de gestión de energía de On.Energy",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Software",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: {
    SectionHead,
    SoftwareWelcome,
    Interface,
    Features,
    Applications,
    SoftwareKpis,
    ContactUs,
  },
  data: () => ({
    //
  }),
};
</script>
