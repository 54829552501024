<template>
  <v-row>
    <v-col class="d-flex flex-column flex-wrap" cols="12">
      <div class="mb-6 text-h3 text-md-h2 font-weight-bold black--text">
        <span class="primary--text">
          {{ $t("proj_operation_title") }}
        </span>
        <br />
        {{ $t("proj_operation_text") }}
      </div>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4" v-for="(item, i) in items" :key="i">
          <Card
            class="px-3"
            :title="item.title"
            :front="item.front"
            :image="item.image"
            :application="item.application"
            :details="item.details"
            :location="item.location"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Card from "@/components/projects/Card.vue";
import infoProjectsOperational from "@/api/infoProjectsOperational.json";
import _ from "lodash";
import { createI18n } from "@/i18n";

const messages = {
  en: {
    proj_operation_title: "operational",
    proj_operation_text: "projects",
    // English
  },
  es: {
    proj_operation_title: "proyectos",
    proj_operation_text: "operativos",
    // Español
  },
};

createI18n(messages);

export default {
  name: "ProjectsOperational",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: {
    Card,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async loadProjectData() {
      try {
        const locale = this.$i18n.locale;
        const file =
          locale === "es"
            ? "infoProjectsOperational-es.json"
            : "infoProjectsOperational.json";

        // Importación dinámica del archivo JSON
        const data = await import(`@/api/${file}`);
        this.items = data.default;
      } catch (error) {
        console.error("Error al cargar los datos del proyecto:", error);
      }
    },
  },
  watch: {
    "$i18n.locale": {
      immediate: true, // Ejecuta la función al montar el componente
      handler() {
        this.loadProjectData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-3d-slide {
  background-color: white;
  opacity: 0 !important;
  z-index: 970 !important;

  &.current {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.right-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.left-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }
}
</style>
