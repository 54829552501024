<template>
  <v-card
    color="black"
    flat
    tile
    style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
  >
    <ContactUs />
  </v-card>
</template>

<script>
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "Contact",
  components: {
    ContactUs,
  },
  data: () => ({
    //
  }),
};
</script>
