<template>
  <v-card color="black" dark flat tile style="padding: 80px 0">
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col class="text-center" cols="12">
          <div
            class="mx-auto text-h3 font-weight-semibold mb-6 text-center primary--text"
          >
            {{ $t("map_title") }}
          </div>

          <div class="mx-auto text-h5 font-weight-semibold mb-8 text-center">
            {{ $t("map_text") }}
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-12">
        <v-col cols="12">
          <v-img
            alt="On.Energy Map"
            class="mx-auto"
            contain
            src="@/assets/elements/map2025.webp"
            height="100%"
            transition="fade-transition"
            width="100%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    map_title: "project map",
    map_text: "operating / in-construction projects",
    // English
  },
  es: {
    map_title: "mapa de proyectos",
    map_text: "proyectos en operación / en construcción",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Map",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
