<template>
  <v-row class="py-16">
    <v-col cols="12">
      <v-col cols="12">
        <v-row style="position: relative">
          <v-col class="d-flex flex-column flex-wrap" cols="12">
            <div class="text-h3 font-weight-bold text--underline">
              {{ $t("latest_title") }}
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-row style="position: relative">
        <v-col cols="12" md="4">
          <Card
            class="px-3"
            :title="$t('latest_1_title')"
            image="projects/maverick-3.webp"
            application="10MW - 20MWh"
            :details="$t('latest_operating1')"
            location="alvin, texas"
            :front="true"
          />
        </v-col>

        <v-col cols="12" md="4">
          <Card
            class="px-3"
            :title="$t('latest_2_title')"
            image="projects/crosby.webp"
            application="10MW - 20MWh"
            :details="$t('latest_operating1')"
            location="crosby, texas"
            :front="true"
          />
        </v-col>

        <v-col cols="12" md="4">
          <Card
            class="px-3"
            :title="$t('latest_3_title')"
            image="projects/spencer-3.webp"
            application="10MW - 20MWh"
            :details="$t('latest_operating1')"
            :front="true"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col class="d-flex justify-center" cols="12">
      <v-btn
        to="/projects"
        class="font-weight-bold black--text mt-6 px-16 py-8 rounded-0"
        color="primary"
        elevation="0"
        x-large
      >
        {{ $t("latest_cta") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.carousel-3d-slide {
  background-color: white;
  opacity: 0 !important;
  z-index: 970 !important;

  &.current {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.right-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.left-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }
}
</style>

<script>
import { createI18n } from "@/i18n";
import Card from "@/components/projects/Card.vue";

const messages = {
  en: {
    latest_title: "latest projects",
    latest_1_title: "maverick - palo verde portfolio",
    latest_2_title: "crosby - palo verde portfolio",
    latest_3_title: "spencer - palo verde portfolio",
    latest_operating1: "operating in the ERCOT grid",
    latest_cta: "more projects",
    // English
  },
  es: {
    latest_title: "últimos proyectos",
    latest_1_title: "maverick - portafolio palo verde",
    latest_2_title: "crosby - portafolio palo verde",
    latest_3_title: "spencer - portafolio palo verde",
    latest_operating1: "operando en la red ERCOT",
    latest_cta: "más proyectos",
    // Español
  },
};

createI18n(messages);

export default {
  name: "LatestProjects",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: {
    Card,
  },
  data: () => ({
    //
  }),
  methods: {
    goNext() {
      this.$refs.mycarousel.goNext();
    },
    goPrev() {
      this.$refs.mycarousel.goPrev();
    },
  },
};
</script>
