<template>
  <v-card
    :img="require(`@/assets/${image}`)"
    class="section__image d-flex align-center justify-center text-center"
    color="black"
    dark
    flat
    height="700"
    tile
  >
    <v-container style="max-width: 800px">
      <v-row>
        <v-col class="d-flex flex-column align-center justify-center" cols="12">
          <div
            class="mb-6 pt-16 pt-md-0 text-h3 text-md-h2 font-weight-bold primary--text notranslate"
          >
            {{ title }}
          </div>

          <div class="text-h5 font-weight-semibold">
            {{ text }}
          </div>

          <v-row class="mt-10">
            <v-col>
              <v-btn
                class="font-weight-bold rounded-0 mx-6 pl-10 pr-10 text-uppercase black--text"
                color="primary"
                elevation="0"
                href="/projects#usa"
                x-large
              >
                USA
              </v-btn>

              <v-btn
                class="font-weight-bold rounded-0 mx-6 pl-10 pr-10 text-uppercase black--text"
                color="primary"
                elevation="0"
                href="/projects#latam"
                x-large
              >
                {{ $t("btn_MXLATAM") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    btn_MXLATAM: "MEXICO/LATAM",
    // English
  },
  es: {
    btn_MXLATAM: "MÉXICO/LATAM",
    // Español
  },
};

createI18n(messages);

export default {
  name: "SectionHeadBtn",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
  props: ["title", "text", "image"],
};
</script>
