<template>
  <v-card class="kpis pt-10" color="primary" flat tile style="padding-bottom: 160px">
    <v-container class="py-8 py-md-3">
      <v-row>
        <v-col
          color="primary"
          class="text-alt text-caption font-weight-black text-center text-md-right text-lowercase"
          flat
          cols="12"
        >
          {{ $t("feat_high") }}
        </v-col>
      </v-row>

      <v-row>
        <v-col color="primary" flat cols="12">
          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <v-img
              alt="Palo Verde"
              contain
              height="192"
              max-width="100%"
              src="@/assets/logos/palo-verde.webp"
              transition="fade-transition"
              width="400"
            />
          </v-col>

          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <span class="btn-porfolio text-body-2 font-weight-bold">
              {{ $t("feat_port") }}
            </span>
          </v-col>

          <v-col class="d-flex align-center flex-column text-center mb-6" cols="12">
            <span class="text-h5 v-application text-weight-bold">Texas, USA</span>
          </v-col>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col
          class="order-1 order-md-2 pa-0"
          cols="12"
          md="3"
          sm="6"
          v-for="(item, i) in orderedItems"
          :index="i"
          v-bind:key="item.id"
        >
          <CardFeatured
            :title="item.title"
            :image="item.image"
            :index="item.index"
            :location="item.location.toLocaleLowerCase()"
            :highlights="item.highlights"
            :front="item.front"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss">
.btn-porfolio {
  border: 2px solid #000;
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 900;
}
</style>

<script>
import CardFeatured from "@/components/projects/CardFeatured.vue";
import { createI18n } from "@/i18n";

const messages = {
  en: {
    feat_high: "highlighted operational projects",
    feat_port: "on.energy storage portfolio",
    feat_hight_1:
      "Strategically connected to Centerpoint Energy Crosby Spencer-Reit substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
    feat_hight_2:
      "Strategically connected to Centerpoint Energy Crosby Spencer-Reit substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
    feat_hight_3:
      "Strategically connected to Centerpoint Energy Crosby substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
    feat_hight_4:
      "Strategically connected to Centerpoint Energy Mustang Bayou substation, it is poised to deliver clean and reliable energy to the Electric Reliability Council of Texas (ERCOT) grid",
    // English
  },
  es: {
    feat_high: "proyectos operativos destacados",
    feat_port: "portafolio de almacenamiento de on.energy",
    feat_hight_1:
      "Conectado estratégicamente a la subestación Centerpoint Energy Crosby Spencer-Reit, está preparado para entregar energía limpia y confiable a la red del Consejo de Confiabilidad Eléctrica de Texas (ERCOT)",
    feat_hight_2:
      "Conectado estratégicamente a la subestación Centerpoint Energy Crosby Spencer-Reit, está preparado para entregar energía limpia y confiable a la red del Consejo de Confiabilidad Eléctrica de Texas (ERCOT)",
    feat_hight_3:
      "Conectado estratégicamente a la subestación Centerpoint Energy Crosby, está preparado para proporcionar energía limpia y confiable a la red del Consejo de Confiabilidad Eléctrica de Texas (ERCOT)",
    feat_hight_4:
      "Conectado estratégicamente a la subestación Centerpoint Energy Mustang Bayou, está preparado para proporcionar energía limpia y confiable a la red del Consejo de Confiabilidad Eléctrica de Texas (ERCOT)",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Featured",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: { CardFeatured },
  data: () => ({
    dialog: false,
  }),
  computed: {
    orderedItems: function () {
      return _.orderBy(this.items, "index", "desc");
    },
    items() {
      return [
        {
          image: "projects/winmil-4.webp",
          index: "1",
          title: "winmil",
          location: "Angleton, Texas.",
          highlights: this.$t("feat_hight_1"),
          front: false,
        },
        {
          image: "projects/spencer-3.webp",
          index: "2",
          title: "spencer",
          location: "Pasadena, Texas.",
          highlights: this.$t("feat_hight_2"),
          front: false,
        },
        {
          image: "projects/crosby.webp",
          index: "3",
          title: "crosby",
          location: "Crosby, Texas",
          highlights: this.$t("feat_hight_3"),
          front: false,
        },
        {
          image: "projects/maverick-3.webp",
          index: "4",
          title: "maverick",
          location: "Alvin, Texas.",
          highlights: this.$t("feat_hight_4"),
          front: false,
        },
      ];
    },
  },
};
</script>
