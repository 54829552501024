<template>
  <div>
    <SectionHeadBtn
      :title="$t('projects_title')"
      :text="$t('projects_text')"
      image="hero/projects2025.webp"
    />

    <Featured id="usa" />

    <v-card class="software" color="black" flat tile>
      <Map />
    </v-card>

    <FeaturedPaloDeAgua />

    <v-container id="latam" style="padding-top: 160px; padding-bottom: 160px">
      <ProjectsOperational />
    </v-container>

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import SectionHeadBtn from "@/components/SectionHeadBtn.vue";
import Featured from "@/components/projects/Featured.vue";
import Map from "@/components/projects/Map.vue";
import FeaturedPaloDeAgua from "@/components/projects/FeaturedPaloDeAgua.vue";
import ProjectsOperational from "@/components/projects/ProjectsOperational.vue";
import Card from "@/components/projects/Card.vue";
import ContactUs from "@/components/ContactUs.vue";
import { createI18n } from "@/i18n";

const messages = {
  en: {
    projects_title: "projects",
    projects_text: "successfully and safely deployed projects for the americas",
    // English
  },
  es: {
    projects_title: "proyectos",
    projects_text: "proyectos desplegados con éxito y seguridad en las Américas",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Projects",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: {
    SectionHeadBtn,
    Featured,
    Map,
    FeaturedPaloDeAgua,
    ProjectsOperational,
    Card,
    ContactUs,
  },
};
</script>
