<template>
  <v-card color="transparent" flat>
    <v-card-title
      class="align-end mb-6 pa-0 text-h6 black--text notranslate"
      style="height: 64px; word-break: normal"
    >
      {{ title }}
    </v-card-title>

    <v-img
      alt="On.Energy Project"
      class="mb-3"
      height="300px"
      cover
      :src="require(`@/assets/${image}`)"
    />

    <v-card-text class="d-flex align-center flex-wrap pa-0">
      <v-col class="d-flex align-center justify-space-between px-0" cols="12" md="6">
        <v-chip
          :class="`mr-4 rounded text-caption text-lowecase ${
            front ? 'black--text' : 'white--text'
          }`"
          :color="front ? 'primary' : 'black'"
        >
          {{ front ? $t("front") : $t("behind") }}
        </v-chip>
      </v-col>

      <v-col
        class="px-0 text-body-1 text-alt text-md-right black--text"
        cols="12"
        md="6"
        style="line-height: 1"
      >
        {{ application }}
      </v-col>

      <v-col v-if="details" class="px-0 text-body-2 black--text" cols="12">
        <span>{{ details }}</span>
        <br />
        {{ location }}
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    front: "front of the meter",
    behind: "behind the meter",
    // English
  },
  es: {
    front: "frente al medidor",
    behind: "detrás del medidor",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Card",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
  props: {
    title: {
      type: String,
      default: "BESS Monterrey",
    },
    image: {
      type: String,
      default: "elements/hiring.webp",
    },
    front: {
      type: Boolean,
      default: false,
    },
    application: {
      type: String,
      default: "frequency regulation",
    },
    details: {
      type: String,
      default: null,
    },
    location: {
      type: String,
      default: null,
    },
  },
};
</script>
