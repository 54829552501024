import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Carousel3d from "vue-carousel-3d";
import VueGtag from "vue-gtag";
import axios from "axios";

Vue.use(Carousel3d);
Vue.use(VueI18n);

Vue.config.productionTip = false;
let useAnalytics =
  process.env.VUE_APP_ENABLED_ANALYTICS === "true" &&
  process.env.VUE_APP_ANALYTICS_ID !== "";

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_ANALYTICS_ID,
      disableScriptLoad: !useAnalytics,
      enabled: useAnalytics,
    },
  },
  router
);

const i18n = new VueI18n({
  locale: "en", // idioma por defecto
  fallbackLocale: "en",
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
  async created() {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const countryCode = response.data.country_code;
      const spanishSpeakingCountries = [
        "AR",
        "BO",
        "CL",
        "CO",
        "CR",
        "CU",
        "DO",
        "EC",
        "SV",
        "GQ",
        "GT",
        "HN",
        "MX",
        "NI",
        "PA",
        "PY",
        "PE",
        "PR",
        "ES",
        "UY",
        "VE",
      ];

      if (spanishSpeakingCountries.includes(countryCode)) {
        this.$i18n.locale = "es";
      } else {
        this.$i18n.locale = "en";
      }
    } catch (error) {
      console.error("Error al obtener la ubicación del usuario:", error);
    }
  },
}).$mount("#app");
