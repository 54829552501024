<template>
  <div class="mx-auto overflow-hidden">
    <v-app-bar
      app
      color="transparent"
      dark
      elevate-on-scroll
      elevation="0"
      height="120"
      scroll-threshold="500"
      shrink-on-scroll
    >
      <v-row class="d-flex align-center justify-space-between ma-0" style="height: 100%">
        <v-col class="pa-0" cols="5" md="6" lg="5" xl="4">
          <v-container
            class="d-flex align-center"
            style="height: 100%; max-width: 600px; padding: 0 20px"
          >
            <router-link to="/">
              <v-img
                alt="On.Energy"
                class="mr-2"
                contain
                height="60"
                max-width="98"
                src="@/assets/logos/on-energy-light.svg"
                width="98"
              />
            </router-link>
          </v-container>
        </v-col>

        <v-col
          class="d-flex align-center pa-0"
          cols="7"
          md="6"
          lg="7"
          xl="8"
          style="height: 100%"
        >
          <v-spacer />

          <v-btn
            class="d-none d-md-flex"
            color="primary"
            elevation="0"
            small
            text
            @click="setLanguage('es')"
          >
            esp
          </v-btn>

          <v-btn
            class="d-none d-md-flex"
            color="primary"
            elevation="0"
            small
            text
            @click="setLanguage('en')"
          >
            eng
          </v-btn>

          <v-app-bar-nav-icon
            @click="drawer = true"
            aria-label="Menu icon"
            class="mr-6"
            color="primary"
            x-large
          />

          <v-btn
            to="/contact"
            class="px-3 px-sm-14 font-weight-bold black--text text-body-2 text-sm-body-1"
            color="primary"
            elevation="0"
            height="100%"
            tile
            x-large
            style="letter-spacing: 0.0892857143em !important"
          >
            {{ $t("cta") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      color="black"
      dark
      fixed
      right
      temporary
      width="1000"
    >
      <v-list class="px-md-16" nav style="width: 100%">
        <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
          <v-list-item to="/">
            <div class="font-weight-bold text-h5 text-md-h4 notranslate">
              {{ $t("menu_home") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item class="mb-0" disabled>
            <div class="text-body-1 text-alt">
              {{ $t("menu_solutions") }}
            </div>
          </v-list-item>

          <v-list-item to="/solutions/end-to-end">
            <div class="font-weight-bold text-h5 text-md-h4 notranslate">
              {{ $t("menu_end") }}
            </div>
          </v-list-item>

          <v-list-item to="/solutions/software">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_software") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item to="/projects">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_projects") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item to="/team">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_team") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item
            href="https://www.onenergynews.com/blog"
            exact-path
            target="_blank"
          >
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_news") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item to="/contact">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_contact") }}
            </div>
          </v-list-item>

          <v-divider class="pb-2" />

          <v-list-item href="https://jobs.on-es.com/" exact-path target="_blank">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_careers") }}
            </div>
          </v-list-item>

          <v-divider class="d-md-none pb-2" />

          <div class="d-md-none">
            <v-btn color="primary" elevation="0" small text @click="setLanguage('es')">
              esp
            </v-btn>

            <v-btn color="primary" elevation="0" small text @click="setLanguage('en')">
              eng
            </v-btn>
          </div>

          <!-- <v-divider class="pb-2" />

          <v-list-item href="https://www.on.energy/shop/" exact-path target="_blank">
            <div class="font-weight-bold text-h5 text-md-h4">
              {{ $t("menu_shop") }}
            </div>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    cta: "Let's Connect",
    menu_home: "home",
    menu_solutions: "solutions",
    menu_end: "end-to-end",
    menu_software: "software",
    menu_projects: "projects",
    menu_team: "team",
    menu_news: "news",
    menu_contact: "contact us",
    menu_careers: "careers",
    menu_shop: "shop",
    menu_language: "language",
    // English
  },
  es: {
    cta: "contacto",
    menu_home: "inicio",
    menu_solutions: "soluciones",
    menu_end: "integral",
    menu_software: "software",
    menu_projects: "proyectos",
    menu_team: "equipo",
    menu_news: "noticias",
    menu_contact: "contáctanos",
    menu_careers: "empleos",
    menu_shop: "tienda",
    menu_language: "idioma",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Header",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
