<template>
  <div>
    <div>
      <SectionHead
        :title="$t('team_title')"
        :text="$t('team_text')"
        image="hero/team.webp"
      />
    </div>

    <v-row style="padding: 0; margin: 0; background-color: #000">
      <v-col
        v-for="(item, i) in items"
        :key="i"
        md="4"
        sm="6"
        xs="12"
        class="d-flex flex-column"
        style="padding: 0; margin: 0"
      >
        <CardTeam
          :teamMember="item.name.toLocaleLowerCase()"
          :picture="item.picture"
          :position="item.position"
          :background="item.background"
          :resume="item.resume.toLocaleLowerCase()"
          :linkedin="item.linkedin"
        />
      </v-col>

      <v-col
        md="4"
        sm="6"
        xs="12"
        class="d-flex flex-column"
        style="padding: 0; margin: 0"
      >
        <v-card color="primary" flat height="100%" tile>
          <v-row style="padding: 0; margin: 0; height: 100%">
            <v-col md="1" />

            <v-col class="align-self-center" md="10">
              <div style="width: 100%; display: flex">
                <v-img
                  alt="On.Energy Project"
                  class="mb-3"
                  contain
                  height="100%"
                  :src="imageTeam"
                  width="100%"
                />
              </div>
            </v-col>

            <v-col md="1" />
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-card color="black" flat tile style="padding: 80px">
      <CompanyCulture />
    </v-card>

    <div>
      <v-card color="black" flat tile style="overflow: hidden; padding-bottom: 80px">
        <ContactUs />
      </v-card>
    </div>
  </div>
</template>

<script>
import SectionHead from "@/components/SectionHead.vue";
import CardTeam from "@/components/team/CardTeam.vue";
import CompanyCulture from "@/components/team/CompanyCulture.vue";
import ContactUs from "@/components/ContactUs.vue";
import { createI18n } from "@/i18n";

const messages = {
  en: {
    team_title: "our team",
    team_text: "behind On",
    team_image: "@/assets/team/team.webp",
    // English
  },
  es: {
    team_title: "nuestro equipo",
    team_text: "detrás de On",
    team_image: "@/assets/team/team.webp",
    // Español
  },
};

createI18n(messages);

var height = 200;

export default {
  name: "Team",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: { SectionHead, CardTeam, CompanyCulture, ContactUs },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async loadProjectData() {
      try {
        const locale = this.$i18n.locale;
        const file = locale === "es" ? "infoTeam-es.json" : "infoTeam.json";

        // Importación dinámica del archivo JSON
        const data = await import(`@/api/${file}`);
        this.items = data.default;
      } catch (error) {
        console.error("Error al cargar los datos del proyecto:", error);
      }
    },
  },
  watch: {
    "$i18n.locale": {
      immediate: true, // Ejecuta la función al montar el componente
      handler() {
        this.loadProjectData();
      },
    },
  },
  computed: {
    imageTeam() {
      return require(`@/assets${this.$i18n.t("team_image").replace("@/assets", "")}`);
    },
  },
};
</script>
