<template>
  <div class="mb-9 text-body-2 black--text">
    <v-row>
      <v-col class="mb-6 mb-md-0" cols="12" md="6" lg="4">
        <div class="text-body-1 font-weight-bold" style="padding-left: 32px">
          United States
        </div>

        <div class="d-flex align-start mb-6">
          <a
            aria-label="Link to Miami Office Google Maps"
            href="https://goo.gl/maps/bdkeB43Y5FXT6wCbA"
            class="text-decoration-none"
            target="_blank"
          >
            <v-icon class="mr-2" color="black"> mdi-map-marker </v-icon>
          </a>

          <div class="d-flex flex-column align-start">
            HQ - Miami<br />
            2900 SW 28th Terrace, Suite 701<br />
            Miami, FL 33133<br />
            <a href="tel:+17868172351" class="black--text"> +1 (786) 817-2351 </a>
          </div>
        </div>

        <div class="d-flex align-start">
          <a
            aria-label="Link to Houston Office Google Maps"
            href="https://maps.app.goo.gl/LEMD47TFLRgA7xVE8"
            class="text-decoration-none"
            target="_blank"
          >
            <v-icon class="mr-2" color="black"> mdi-map-marker </v-icon>
          </a>

          <div class="d-flex flex-column align-start">
            Houston - Project Development Office<br />
            1301 McKinney Street - Suite 300<br />
            Houston, Texas 77 010<br />
          </div>
        </div>
      </v-col>

      <v-col class="mb-6 mb-md-0" cols="12" md="6" lg="4">
        <div class="text-body-1 font-weight-bold" style="padding-left: 32px">
          {{ $t("Country_Mexico") }}
        </div>

        <div class="d-flex align-start mb-6">
          <a
            aria-label="Link to Ciudad de Mexico Office Google Maps"
            href="https://maps.app.goo.gl/cMQbzoZuyH8F7Jhc9"
            class="text-decoration-none"
            target="_blank"
          >
            <v-icon class="mr-2" color="black"> mdi-map-marker </v-icon>
          </a>

          <div class="d-flex flex-column align-start">
            Ciudad de {{ $t("Country_Mexico") }}<br />
            Av Cordillera de Los Andes 120, #Oficina 302,<br />
            Lomas de Chapultepec, Miguel Hidalgo, 11000.<br />
            <a href="tel:+525555205465" class="black--text"> +52 55 55 205465 </a>
          </div>
        </div>

        <div class="d-flex align-start">
          <a
            aria-label="Link to Villahermosa Office Google Maps"
            href="https://goo.gl/maps/sX5uyQVe1ZPGprBV6"
            class="text-decoration-none"
            target="_blank"
          >
            <v-icon class="mr-2" color="black"> mdi-map-marker </v-icon>
          </a>

          <div class="d-flex flex-column align-start">
            Villahermosa<br />
            Parque Logístico Industrial Tabasco (PLIT)<br />
            Calle San Remo, Mz. 2, Lt. 1.,<br />
            Bodega 2 CP. 86287.<br />
            Villahermosa, Tabasco
          </div>
        </div>
      </v-col>

      <v-col class="mb-6 mb-md-0" cols="12" md="6" lg="4">
        <div class="text-body-1 font-weight-bold" style="padding-left: 32px">
          {{ $t("Country_Peru") }}
        </div>

        <div class="d-flex align-start">
          <a
            aria-label="Link to Lima Office Google Maps"
            href="https://maps.app.goo.gl/y5gBKnnfKsbHxkhP8"
            class="text-decoration-none"
            target="_blank"
          >
            <v-icon class="mr-2" color="black"> mdi-map-marker </v-icon>
          </a>

          <div class="d-flex flex-column align-start">
            Lima<br />
            Av. 28 de Julio 753, Piso 7, Suite 701,<br />
            Miraflores Centro Empresarial 28 de Julio<br />
            <a href="tel:+51987320417" class="black--text"> +51 987 320 417 </a>
          </div>
        </div>
      </v-col>

      <v-col class="mt-md-9 mb-md-3" cols="12">
        <div class="d-flex align-center">
          <v-btn
            aria-label="Link to X"
            color="black"
            href="https://x.com/ONenergystorage/"
            icon
            target="_blank"
          >
            <v-img
              alt="Link to X"
              contain
              src="@/assets/logos/x.svg"
              transition="fade-transition"
              width="100%"
            />
          </v-btn>

          <v-btn
            aria-label="Link to YouTube"
            color="black"
            href="https://www.youtube.com/channel/UCdGon7GQu6hFOCG8e5g2U0g"
            icon
            target="_blank"
          >
            <v-icon> mdi-youtube </v-icon>
          </v-btn>

          <!-- <v-btn
            aria-label="Link to Facebook"
            color="black"
            href="https://www.facebook.com/On.Energy.Lat"
            icon
            target="_blank"
          >
            <v-icon> mdi-facebook </v-icon>
          </v-btn> -->

          <v-btn
            aria-label="Link to LinkedIn"
            color="black"
            href="https://www.linkedin.com/company/on-es/mycompany/"
            icon
            target="_blank"
          >
            <v-icon> mdi-linkedin </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    Country_Mexico: "Mexico",
    Country_Peru: "Peru",
    // English
  },
  es: {
    Country_Mexico: "México",
    Country_Peru: "Perú",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Offices",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
