<template>
  <div style="position: relative; overflow: hidden">
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col cols="12">
          <v-card
            color="transparent"
            flat
            tile
            height="100%"
            style="padding-bottom: 120px"
          >
            <div class="pt-9 pb-3 text-h4 font-weight-semibold">
              {{ $t("soft_app") }}
            </div>

            <v-list color="transparent" flat>
              <v-list-item-group
                aria-label="List of applications"
                role="list"
                style="pointer-events: none !important"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  inactive
                  role="listitem"
                  style="pointer-events: none !important"
                >
                  <v-list-item-icon>
                    <v-icon size="32"> mdi-square-small </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.toLocaleLowerCase()"
                      class="text-h6"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: 0;
}

.v-list-item__icon {
  margin: 2px 0;
}

.v-list-item__content {
  padding: 4px 0;
}
</style>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    soft_app: "applications",
    app_1: "load shifting",
    app_2: "peaking capacity",
    app_3: "transmission congestion",
    app_4: "ancillary services (black start, frequency reg., voltage reg.)",
    app_5: "uninterruptible power supply (ups)",
    app_6: "grid power backup",
    app_7: "microgrid",
    app_8: "renewable integration",
    app_9: "carbon footprint reduction tracking",
    // English
  },
  es: {
    soft_app: "aplicaciones",
    app_1: "desplazamiento de carga",
    app_2: "capacidad de punta",
    app_3: "congestión de transmisión",
    app_4:
      "servicios auxiliares (arranque en negro, regulación de frecuencia, regulación de voltaje)",
    app_5: "sistema de alimentación ininterrumpida (UPS)",
    app_6: "respaldo de energía de la red",
    app_7: "microred",
    app_8: "integración de energías renovables",
    app_9: "seguimiento de la reducción de la huella de carbono",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Applications",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  computed: {
    items() {
      return [
        this.$t("app_1"),
        this.$t("app_2"),
        this.$t("app_3"),
        this.$t("app_4"),
        this.$t("app_5"),
        this.$t("app_6"),
        this.$t("app_7"),
        this.$t("app_8"),
        this.$t("app_9"),
      ];
    },
  },
};
</script>
