import { render, staticRenderFns } from "./Applications.vue?vue&type=template&id=1962f3b2&scoped=true&"
import script from "./Applications.vue?vue&type=script&lang=js&"
export * from "./Applications.vue?vue&type=script&lang=js&"
import style0 from "./Applications.vue?vue&type=style&index=0&id=1962f3b2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1962f3b2",
  null
  
)

export default component.exports