<template>
  <div>
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col class="mb-12 text-center" cols="12">
          <div class="mb-9 text-h3 font-weight-bold primary--text">
            {{ $t("features_title") }}
          </div>

          <div class="pb-9 text-h5 font-weight-semibold">
            {{ $t("features_text") }}
          </div>
        </v-col>
      </v-row>
    </v-container>

    <SoftwareGallery />
  </div>
</template>

<script>
import { createI18n } from "@/i18n";
import SoftwareGallery from "@/components/solutions/software/SoftwareGallery.vue";

const messages = {
  en: {
    features_title: "features",
    features_text:
      "On.Command™ interface is optimized for superior user experience and management",
    // English
  },
  es: {
    features_title: "características",
    features_text:
      "la interfaz de On.Command™ está optimizada para una experiencia y gestión de usuario superior",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Features",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  components: {
    SoftwareGallery,
  },
  data: () => ({
    //
  }),
};
</script>
