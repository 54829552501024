<template>
  <v-row>
    <v-col cols="12">
      <v-card color="transparent" dark flat tile height="100%">
        <v-container class="py-16">
          <v-row>
            <v-col class="d-flex flex-column align-center text-center" cols="12">
              <div class="pb-12 text-h3 font-weight-bold primary--text">
                {{ $t("welcome_title1") }}
              </div>

              <div class="pb-12 text-h5" style="max-width: 650px">
                {{ $t("welcome_text1") }}<br />
                {{ $t("welcome_text2") }}
              </div>

              <div>
                <v-btn
                  to="/contact"
                  class="font-weight-bold black--text px-16 py-8 rounded-0"
                  color="primary"
                  elevation="0"
                  x-large
                >
                  {{ $t("cta_connect") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    welcome_title1: "safety, quality & operational excellence",
    welcome_text1:
      "we are a fully-integrated energy storage developer, technology company and asset owner.",
    welcome_text2:
      "using proprietary software, we deliver end-to-end projects with available in-house financing",
    cta_connect: "Let's Connect",
    // English
  },
  es: {
    welcome_title1: "seguridad, calidad y excelencia operativa",
    welcome_text1:
      "somos un desarrollador de almacenamiento de energía totalmente integrado, empresa tecnológica y propietario de activos.",
    welcome_text2:
      "utilizando software propio, entregamos proyectos de principio a fin con financiamiento interno disponible",
    cta_connect: "Conectémonos",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Welcome",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
