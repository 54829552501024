<template>
  <v-row style="position: relative; z-index: 2">
    <v-col cols="12">
      <a href="https://www.on.energy/shop/" target="_blank">
        <v-img
          alt="On.Energy Shop"
          class="d-md-none"
          contain
          :src="mobileImage"
          transition="fade-transition"
          width="100%"
        />
        <v-img
          alt="On.Energy Shop"
          class="d-none d-md-block"
          contain
          :src="desktopImage"
          transition="fade-transition"
          width="100%"
        />
      </a>
    </v-col>
  </v-row>
</template>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    desktop: "@/assets/shop/link.webp",
    mobile: "@/assets/shop/link-mobile.webp",
    // English
  },
  es: {
    desktop: "@/assets/shop/link.webp",
    mobile: "@/assets/shop/link-mobile.webp",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Shop",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  computed: {
    mobileImage() {
      return require(`@/assets${this.$i18n.t("mobile").replace("@/assets", "")}`);
    },
    desktopImage() {
      return require(`@/assets${this.$i18n.t("desktop").replace("@/assets", "")}`);
    },
  },
};
</script>
