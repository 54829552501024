<template>
  <v-card color="black" dark flat tile style="padding: 160px 0">
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col class="text-center" cols="12">
          <div
            class="mx-auto text-h3 font-weight-semibold mb-12 text-center onCommand primary--text"
          >
            On.Command™
          </div>

          <div
            class="mx-auto text-h5 font-weight-semibold mb-12 text-center"
            style="max-width: 850px"
          >
            {{ $t("software_text1") }}
            <br />
            {{ $t("software_text2") }}
            <br />
            {{ $t("software_text3") }}
          </div>

          <div class="text-h6 font-weight-semibold">
            {{ $t("software_text4") }}
          </div>
        </v-col>
      </v-row>

      <v-row class="my-12">
        <v-col cols="12">
          <v-img
            alt="On.Energy"
            class="mx-auto"
            contain
            src="@/assets/elements/software2025.webp"
            height="100%"
            transition="fade-transition"
            width="100%"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center" cols="12">
          <v-btn
            to="/solutions/software"
            class="font-weight-bold black--text px-16 py-8 rounded-0"
            color="primary"
            elevation="0"
            x-large
          >
            {{ $t("software_btn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss" scoped>
.onCommand {
  @media screen and (max-width: 600px) {
    font-size: 2em !important;
  }
}
</style>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    software_text1: "we use proprietary technology to",
    software_text2: "develop, design, forecast, control and dispatch our assets",
    software_text3: "squeezing the most out of every kilowatt deployed",
    software_text4:
      "On.Command™ interface is optimized for superior user experience and management",
    software_btn: "our software",
    // English
  },
  es: {
    software_text1: "utilizamos tecnología propia para",
    software_text2:
      "desarrollar, diseñar, prever, controlar y despachar nuestros activos,",
    software_text3: "maximizando el rendimiento de cada kilovatio desplegado",
    software_text4:
      "la interfaz de On.Command™ está optimizada para una experiencia y gestión de usuario superior",
    software_btn: "nuestro software",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Software",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  data: () => ({
    //
  }),
};
</script>
