<template>
  <v-container class="py-8 py-md-3">
    <v-row>
      <v-col cols="12">
        <div
          class="mx-auto text-h2 font-weight-semibold text-center mb-8"
          style="max-width: 800px"
        >
          {{ $t("kpi_title") }}
        </div>

        <div
          class="mx-auto text-h4 font-weight-semibold text-center"
          style="max-width: 800px"
        >
          {{ $t("kpi_slogan") }}
        </div>
      </v-col>

      <v-col
        v-for="(item, i) in items"
        :key="i"
        class="d-flex flex-wrap py-16"
        :class="item.class"
        cols="12"
        sm="6"
        md="3"
      >
        <div
          v-text="item.title"
          class="col-12 pa-0 pb-3 text-h6 font-weight-semibold black--text text-center"
        />

        <v-divider class="col-12" />

        <div
          v-text="item.kpi"
          class="d-flex flex-nowrap align-baseline justify-center kpi col-12 pa-0 text-h3 font-weight-semibold black--text text-center"
        />
      </v-col>

      <v-col
        class="d-flex flex-column justify-center flex-sm-row align-sm-center pt-12 pb-16"
      >
        <v-btn
          to="/solutions/end-to-end"
          class="font-weight-bold primary--text mb-6 mb-sm-0 mr-sm-12 px-16 py-8 rounded-0"
          color="black"
          elevation="0"
          x-large
        >
          {{ $t("btn_solutions") }}
        </v-btn>

        <v-btn
          to="/projects"
          class="font-weight-bold primary--text ml-sm-12 px-16 py-8 rounded-0"
          color="black"
          elevation="0"
          x-large
        >
          {{ $t("btn_projects") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.add-hours {
  .kpi {
    &::after {
      content: "hours";
      font-size: 24px;
      line-height: 1;
      margin-left: 12px;
    }
  }
}
</style>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    kpi_title: "battery storage ipp",
    kpi_slogan:
      "we design, build and operate energy storage solutions to drive forward a low carbon future",
    btn_solutions: "solutions",
    btn_projects: "projects",
    kpi_operation: "in operation",
    kpi_construction: "in construction",
    kpi_development: "in development",
    kpi_constructed: "projects constructed",
    // English
  },
  es: {
    kpi_title: "productor independiente de energía con baterías",
    kpi_slogan:
      "diseñamos, construimos y operamos soluciones de almacenamiento de energía para impulsar un futuro libre de carbono",
    btn_solutions: "soluciones",
    btn_projects: "proyectos",
    kpi_operation: "en operación",
    kpi_construction: "en construcción",
    kpi_development: "en desarrollo",
    kpi_constructed: "proyectos construidos",
    // Español
  },
};

createI18n(messages);

export default {
  name: "HomeKpis",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  computed: {
    items() {
      return [
        {
          title: this.$t("kpi_operation"),
          kpi: "120 MWh",
        },
        {
          title: this.$t("kpi_construction"),
          kpi: "400 MWh",
        },
        {
          title: this.$t("kpi_development"),
          kpi: "3 GWh",
        },
        {
          title: this.$t("kpi_constructed"),
          kpi: "50+",
        },
      ];
    },
  },
};
</script>
