<template>
  <v-container class="py-8 py-md-3">
    <v-row>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        class="d-flex flex-wrap py-16"
        :class="item.class"
        cols="12"
        sm="6"
        md="3"
      >
        <div
          v-text="item.title"
          class="col-12 pa-0 pb-3 text-h6 font-weight-semibold text-center"
        />

        <v-divider class="col-12" />

        <div
          v-text="item.kpi"
          class="d-flex flex-nowrap align-baseline justify-center kpi col-12 pa-0 text-h2 font-weight-semibold text-center"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.add-hours {
  .kpi {
    &::after {
      content: "hrs.";
      font-size: 24px;
      line-height: 1;
      margin-left: 12px;
    }
  }
}
</style>

<script>
import { createI18n } from "@/i18n";

const messages = {
  en: {
    soft_kpi1: "ems combined runtime",
    soft_kpi2: "applications",
    soft_kpi3: "proprietary software",
    soft_kpi4: "remote monitoring",
    // English
  },
  es: {
    soft_kpi1: "ejecución del ems",
    soft_kpi2: "aplicaciones",
    soft_kpi3: "software propietario",
    soft_kpi4: "monitoreo remoto",
    // Español
  },
};

createI18n(messages);

export default {
  name: "Kpis",
  created() {
    Object.keys(messages).forEach((lang) => {
      this.$i18n.mergeLocaleMessage(lang, messages[lang]);
    });
  },
  computed: {
    items() {
      return [
        {
          title: this.$t("soft_kpi1"),
          kpi: "800K+",
          class: "add-hours",
        },
        {
          title: this.$t("soft_kpi2"),
          kpi: "15+",
        },
        {
          title: this.$t("soft_kpi3"),
          kpi: "100%",
        },
        {
          title: this.$t("soft_kpi4"),
          kpi: "24/7",
        },
      ];
    },
  },
};
</script>
